<template>
  <div class="app flex-row align-items-center register">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8" sm="8">
          <b-card no-body class="mx-4 reg-container">
            <b-card-body class="p-4">
              <div v-if="isPageLoading" class="text-center">
                <i class="fa fa-cog fa-spin fa-4x"></i>
              </div>
              <video
                v-if="typeof selectedMember.Id === 'undefined'"
                id="preview"
                ref="preview"
              ></video>
              <MemberData
                v-if="typeof selectedMember.Id !== 'undefined'"
                ref="memberData"
                :member="selectedMember"
                :baseURL="baseURL"
                :images="selectedMemberImages()"
                :getRank="getRank"
              ></MemberData>
              <b-button size="sm" variant="primary" @click="scanMore()">
                <i class="icon-plus"></i> Сканировать другого
              </b-button>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import API from "../api/stalk.net";

const Instascan = require("instascan");

export default {
  name: "Scan",
  components: {},
  data() {
    let scanner = new Instascan.Scanner({
      video: document.getElementById("preview")
    });
    scanner.addListener("scan", function() {
      self.isPageLoading = true;
      API.public
        .scanCode()
        .then(resp => {
          self.selectedMember = resp.data;

          self.isPageLoading = false;
        })
        .catch(this.catchError);
    });
    Instascan.Camera.getCameras()
      .then(function(cameras) {
        if (cameras.length > 0) {
          scanner.start(cameras[0]);
        } else {
          console.error("No cameras found.");
        }
      })
      .catch(function(e) {
        console.error(e);
      });
    return {
      isPageLoading: false,
      selectedMember: {}
    };
  },
  methods: {
    scanMore() {
      this.selectedMember = {};
    }
  },
  beforeMount() {},
  computed: {},
  watch: {},
  notifications: {
    showRegError: {
      title: "Ошибка сканирования",
      type: "error"
    }
  }
};
</script>
